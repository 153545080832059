import {addCart, deleteCart} from "../../../../state/modules/carts";
import {updateList} from "../../../../state/modules/resources";
import connect from "react-redux/es/connect/connect";

import React from 'react';
import ui from "../../../../util/ui";
import Window from "../../window";
import PaginationArrows from "../../pagination-arrows";
import ButtonGroup from "../../button-group";
import Button from "../../../atoms/button";
import CartListItem from "../cart-list-item";
import List from "../../list";
import OrderCompactListItem from "../../order/order-compact-list-item";
import Grid from "../../grid";

class CartIndex extends React.Component {
    static defaultProps = {
        onCartClick: () => {
        },
        onOrderClick: () => {
        },
        onRegisterOpenClick: () => {
        },
        onManageRegisterClick: () => {
        },
    };
    
    constructor(props) {
        super(props);
        
        this.state = {
            registerFound: false,
            register: null,
        }
    }
    
    componentDidMount() {
        // Local storage => Register ID
        // Check if Register is not closed by another User
        
        this.props.updateList('open-registers', {
            model: 'registers',
            filters: {
                filter_closed: [
                    {key: 0}
                ]
            },
            limit: 1
        }).then(() => {
            if (this.props.lists['open-registers']?.items && this.props.lists['open-registers'].items.length) {
                this.setState({
                    registerFound: true,
                    register: this.props.lists['open-registers'].items[0],
                });
            }
        });
    }
    
    render() {
        let activeRegister = <Button text={'Open register'} onClick={this.props.onRegisterOpenClick}/>;
        let addCart = null;
        
        if (this.state.register) {
            activeRegister =
                <Button text={this.state.register.hid + ' - ' + this.state.register.location.hid} modifiers={'outline'}
                        onClick={() => this.props.onManageRegisterClick(this.state.register)}/>
            
            addCart = <Button text="Create new cart"
                              onClick={e => {
                                  this.props.addCart(this.state.register).then(response => {
                                      let cart = response.result;
                                      this.props.updateList('carts');
                                      this.props.onCartClick(cart);
                                      ui.notify('Cart created');
                                  });
                              }}
            />
        }
        
        let checkoutOrder = <Button text={'Checkout order'} onClick={() => this.props.onCheckoutOrderClick()}/>
        
        return (
            <Grid cols="2fr 1fr">
                <Window
                    title="Open carts"
                    modifiers={'depth'}
                    footer={<PaginationArrows listId={'carts'} model={'carts'}/>}
                    header={
                        <ButtonGroup>
                            {activeRegister}
                            {checkoutOrder}
                            {addCart}
                        </ButtonGroup>
                    }
                >
                    <List id={'carts'} model={'carts'} modifiers={'grid'} selectable={false}
                          onItemClick={cart => this.props.onCartClick(cart)}>
                        <CartListItem onDelete={item => {
                            this.props.deleteCart(item.id).then(() => {
                                this.props.updateList('carts');
                                ui.notify('Cart deleted');
                            });
                        }}/>
                    </List>
                </Window>
                <Window modifiers={'depth'} footer={<PaginationArrows listId={'cash-desk-orders'} model={'orders'}/>}>
                    <List
                        id={'cash-desk-orders'}
                        model={'orders'}
                        filters={{
                            filter_type: [
                                {key: 0}
                            ]
                        }}
                        limit={8}
                        sort={{sort_created: 'desc'}}
                        onItemClick={order => this.props.onOrderClick(order)}
                    >
                        <OrderCompactListItem/>
                    </List>
                </Window>
            </Grid>
        );
    }
}

const mapStateToProps = (state) => ({
    carts: state.carts.carts,
    lists: state.resources.lists
});

const mapDispatchToProps = {
    addCart: addCart,
    updateList: updateList,
    deleteCart: deleteCart,
};

export default connect(mapStateToProps, mapDispatchToProps)(CartIndex);